<template>

  <div>
    <v-card
        :loading="loading"
        link
        :to="`/product/${slug}`"
        height="350"
    >
      <template slot="progress">
        <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
        ></v-progress-linear>
      </template>
        <v-img
            height="200"
            :src="`https://ex.exhotelbali.com/storage/${thumbnail}`"
        ></v-img>

      <!-- Badge overlay DIV -->
      <div class="badge-overlay" v-if="stock === 0">
        <!-- Change Badge Position, Color, Text here-->
        <span class="top-right badge red">SOLD</span>
      </div>


      <v-card-title class="titleProducts">{{name}}</v-card-title>

      <v-card-text>
        <div class="bahanProducts d-flex justify-space-between">
          <div class="priceProducts">IDR {{formatPrice(price) }}</div>
          <v-spacer />
          <span>Stock : {{stock}}</span>
        </div>
<!--        <div class="bahanProducts d-flex justify-space-between">-->
          <span class="bahanProducts">Bahan : {{bahan}}</span>
          <v-spacer />
          <span class="bahanProducts">Dimensi : {{dimensi}}</span>
<!--        </div>-->
<!--        <div class="bahanProducts d-flex justify-space-between">-->
<!--          <span>Stock : {{stock}}</span>-->
<!--        </div>-->

<!--        <div v-html="formatText(description)"></div>-->
      </v-card-text>

<!--      <v-divider class="mx-4"></v-divider>-->

<!--      <v-card-actions>-->
<!--        <v-btn-->
<!--            color="primary"-->
<!--            dark-->
<!--            small-->
<!--            outlined-->
<!--            :to="`/product/${slug}`"-->
<!--        >-->
<!--          Show Detail-->
<!--          <v-icon small>mdi-arrow-right</v-icon>-->
<!--        </v-btn>-->
<!--      </v-card-actions>-->
    </v-card>

  </div>
</template>

<script>
  export default {
    props: {
      loading: String,
      thumbnail: String,
      name: String,
      slug: String,
      bahan: String,
      dimensi: Number,
      stock: Number,
      description: String,
      price: Number
    },
    data () {
      return {

      }
    },

    methods: {
      formatPrice (value) {
        let val = (value/1).toFixed(0).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },

      formatText (value) {
        let val = value.substring(0,100) + "..."
        return val
      }
    }
  }
</script>

<style>
.titleProducts {
  font-family: 'Poppins', sans-serif;
  line-height: 16px !important;
  margin-bottom: 0;
  font-weight: 300;
  font-size: 0.86rem;
  color: rgba(49, 53, 59, 0.96);
  letter-spacing: 0.001em;
  padding-top: 15px;
  padding-bottom: 10px;
}
.priceProducts {
  font-weight: 600 !important;
  font-size: 14px !important;
}

.bahanProducts {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.001em;
}

.badge-overlay {
  position: absolute;
  left: 0%;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 2;
  -webkit-transition: width 1s ease, height 1s ease;
  -moz-transition: width 1s ease, height 1s ease;
  -o-transition: width 1s ease, height 1s ease;
  transition: width 0.4s ease, height 0.4s ease
}

/* ================== Badge CSS ========================*/
.badge {
  margin: 0;
  padding: 0;
  color: white;
  padding: 5px 5px;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  line-height: normal;
  text-transform: uppercase;
  background: #ed1b24;
}

.badge::before, .badge::after {
  content: '';
  position: absolute;
  top: 0;
  margin: 0 -1px;
  width: 100%;
  height: 100%;
  background: inherit;
  min-width: 55px
}

.badge::before {
  right: 100%
}

.badge::after {
  left: 100%
}

/* ================== Badge Position CSS ========================*/
.top-left {
  position: absolute;
  top: 0;
  left: 0;
  -ms-transform: translateX(-30%) translateY(0%) rotate(-45deg);
  -webkit-transform: translateX(-30%) translateY(0%) rotate(-45deg);
  transform: translateX(-30%) translateY(0%) rotate(-45deg);
  -ms-transform-origin: top right;
  -webkit-transform-origin: top right;
  transform-origin: top right;
}

.top-right {
  position: absolute;
  top: 0;
  right: 0;
  -ms-transform: translateX(30%) translateY(0%) rotate(45deg);
  -webkit-transform: translateX(30%) translateY(0%) rotate(45deg);
  transform: translateX(30%) translateY(0%) rotate(45deg);
  -ms-transform-origin: top left;
  -webkit-transform-origin: top left;
  transform-origin: top left;
}

.bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
  -ms-transform: translateX(-30%) translateY(0%) rotate(45deg);
  -webkit-transform: translateX(-30%) translateY(0%) rotate(45deg);
  transform: translateX(-30%) translateY(0%) rotate(45deg);
  -ms-transform-origin: bottom right;
  -webkit-transform-origin: bottom right;
  transform-origin: bottom right;
}

.bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  -ms-transform: translateX(30%) translateY(0%) rotate(-45deg);
  -webkit-transform: translateX(30%) translateY(0%) rotate(-45deg);
  transform: translateX(30%) translateY(0%) rotate(-45deg);
  -ms-transform-origin: bottom left;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}

.top-full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.middle-full {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  -ms-transform: translateX(0%) translateY(-50%) rotate(0deg);
  -webkit-transform: translateX(0%) translateY(-50%) rotate(0deg);
  transform: translateX(0%) translateY(-50%) rotate(0deg);
}

.bottom-full {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

/* ================== Badge color CSS ========================*/
.badge.red {
  background: #ed1b24;
}

.badge.orange {
  background: #fa7901;
}

.badge.pink {
  background: #ee2b8b;
}

.badge.blue {
  background: #00adee;
}

.badge.green {
  background: #b4bd00;
}

</style>
