import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import vuetify from "./plugins/vuetify";
import VueMeta from 'vue-meta';

import store from "./Store";
import axios from "axios";

axios.defaults.baseURL = "https://ex.exhotelbali.com/api/v1";
// axios.defaults.headers.common['Authorization'] = 'fasfdsa'
axios.defaults.headers.get["Accepts"] = "application/json";
// axios.defaults.headers.get['Authorization'] = 'Bearer ' + localStorage.getItem('token')
// axios.defaults.headers.post['Content-Type'] = 'application/json'

const reqInterceptor = axios.interceptors.request.use((config) => {
  console.log("Request Interceptor", config);
  return config;
});
const resInterceptor = axios.interceptors.response.use((res) => {
  console.log("Response Interceptor", res);
  return res;
});

axios.interceptors.request.eject(reqInterceptor);
axios.interceptors.response.eject(resInterceptor);

// AUTH
router.beforeEach((to, from, next) => {
  // mengecek ada tidak meta auth di dalam meta
  if (to.matched.some((record) => record.meta.auth)) {
    // cek di localstorage auth, jika false maka diarahkan ke halaman login
    if (!localStorage.getItem("token")) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

Vue.config.productionTip = false;

Vue.use(VueMeta, {
  keyName: 'head'
})

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
