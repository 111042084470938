<template>
    <v-container>
        <h4>Other Products</h4>
        <v-row>
            
                    
            <v-col md="4">
                <v-card
                    :loading="loading"
                >
                    <template slot="progress">
                    <v-progress-linear
                        color="deep-purple"
                        height="10"
                        indeterminate
                    ></v-progress-linear>
                    </template>

                    <v-img
                    height="200"
                    src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
                    ></v-img>

                    <v-card-title class="titleProducts">Meja Kantor Second</v-card-title>

                    <v-card-text>
                        <div class="priceProducts">IDR 2.000.000</div>
                        <div class="bahanProducts d-flex justify-space-between">
                            <span>Bahan : Kayu Jati</span>
                            <v-spacer />
                            <span>Dimensi : 100 x 200 cm</span>
                        </div>

                    </v-card-text>

                    <v-divider class="mx-4"></v-divider>

                    <v-card-actions>
                    <v-btn
                        color="primary"
                        dark
                        small
                        outlined
                        to="/product/detail"
                    >
                    Show Detail
                    <v-icon small>mdi-arrow-right</v-icon>
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col md="4">
                <v-card
                    :loading="loading"
                >
                    <template slot="progress">
                    <v-progress-linear
                        color="deep-purple"
                        height="10"
                        indeterminate
                    ></v-progress-linear>
                    </template>

                    <v-img
                    height="200"
                    src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
                    ></v-img>

                    <v-card-title class="titleProducts">Meja Kantor Second</v-card-title>

                    <v-card-text>
                        <div class="priceProducts">IDR 2.000.000</div>
                        <div class="bahanProducts d-flex justify-space-between">
                            <span>Bahan : Kayu Jati</span>
                            <v-spacer />
                            <span>Dimensi : 100 x 200 cm</span>
                        </div>

                    </v-card-text>

                    <v-divider class="mx-4"></v-divider>

                    <v-card-actions>
                    <v-btn
                        color="primary"
                        dark
                        small
                        outlined
                        to="/product/detail"
                    >
                    Show Detail
                    <v-icon small>mdi-arrow-right</v-icon>
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col md="4">
                <v-card
                    :loading="loading"
                >
                    <template slot="progress">
                    <v-progress-linear
                        color="deep-purple"
                        height="10"
                        indeterminate
                    ></v-progress-linear>
                    </template>

                    <v-img
                    height="200"
                    src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
                    ></v-img>

                    <v-card-title class="titleProducts">Meja Kantor Second</v-card-title>

                    <v-card-text>
                        <div class="priceProducts">IDR 2.000.000</div>
                        <div class="bahanProducts d-flex justify-space-between">
                            <span>Bahan : Kayu Jati</span>
                            <v-spacer />
                            <span>Dimensi : 100 x 200 cm</span>
                        </div>

                    </v-card-text>

                    <v-divider class="mx-4"></v-divider>

                    <v-card-actions>
                    <v-btn
                        color="primary"
                        dark
                        small
                        outlined
                        to="/product/detail"
                    >
                    Show Detail
                    <v-icon small>mdi-arrow-right</v-icon>
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
                
            
        </v-row>
        
    </v-container>
</template>

<script>
  export default {
    data: () => ({
      loading: false,
      selection: 1,
      items: [
          { title: 'New Arival', icon: 'mdi-view-dashboard' },
          { title: 'New Products', icon: 'mdi-forum' },
          { title: 'Popular Products', icon: 'mdi-forum' },
        ],
        categories: [
          { title: 'Meja', icon: 'mdi-view-dashboard' },
          { title: 'Kursi', icon: 'mdi-forum' },
          { title: 'Dipan', icon: 'mdi-forum' },
        ],
    }),

    methods: {
      reserve () {
        this.loading = true

        setTimeout(() => (this.loading = false), 2000)
      },
    },
  }
</script>

<style scoped>
    .btnShowMore {
        text-align: center;
        padding: 30px 0;
    }
    .priceProducts {
        font-size: 16px;
        font-weight: bold;
    }
    .bahanProducts {
        font-size: 12px;
        margin-bottom: 10px;
    }
    .titleProducts {
        padding-top: 5px;
        padding-bottom: 10px;
    }
    .navDrawer {
        border-radius: 10px;
    }
    .titleNavDrawer {
        padding-left: 23px;
    }
</style>
