<template>
    <v-container>
        <v-row>
            <v-col md="3">
                <v-card
                    class="mx-auto"
                    color="#26c6da"
                    dark
                    max-width="400"
                >
                    <v-card-title>
                        <span class="text-h6 font-weight-light" align="center">Total Views</span>
                    </v-card-title>

                    <v-card-text class="text-h2 font-weight-bold">
                        100
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="3">
                <v-card
                    class="mx-auto"
                    color="#26c6da"
                    dark
                    max-width="400"
                >
                    <v-card-title>
                        <span class="text-h6 font-weight-light" align="center">Click Contact</span>
                    </v-card-title>

                    <v-card-text class="text-h2 font-weight-bold">
                        100
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="3">
                <v-card
                    class="mx-auto"
                    color="#26c6da"
                    dark
                    max-width="400"
                >
                    <v-card-title>
                        <span class="text-h6 font-weight-light" align="center">Total Products</span>
                    </v-card-title>

                    <v-card-text class="text-h2 font-weight-bold">
                        100
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="3">
                <v-card
                    class="mx-auto"
                    color="#26c6da"
                    dark
                    max-width="400"
                >
                    <v-card-title>
                        <span class="text-h6 font-weight-light" align="center">Total Photos</span>
                    </v-card-title>

                    <v-card-text class="text-h2 font-weight-bold">
                        100
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- <v-row>
            <v-col cols="12">
                <v-card
                    class="mx-auto text-center"
                    color="cyan"
                    dark
                >
                    <v-card-text>
                    <v-sheet class="v-sheet--offset mx-auto"
                        color="cyan"
                        elevation="12"
                        max-width="calc(100% - 32px)">
                        <v-sparkline
                           :labels="labels"
                            :value="value"
                            color="white"
                            line-width="1"
                            padding="16"
                        >
                        <template v-slot:label="item">
                            {{ item.value }}
                        </template>
                        </v-sparkline>
                    </v-sheet>
                    </v-card-text>

                    <v-card-text>
                    <div class="text-h4 font-weight-thin">
                        Total Views Last 1 Month
                    </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row> -->
    </v-container>
</template>

<script>
  export default {
    data: () => ({
      value: [
        0,
        3,
        10,
        3,
        5,
        10,
        50,
        0,
        3,
        10,
        3,
        5,
        10,
        50,
        0,
        3,
        10,
        3,
        5,
        10,
        50,
        0,
        3,
        10,
        3,
        5,
        10,
        50,
      ],
    }),
  }
</script>