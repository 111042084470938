import globalAxios from "../../axios-global";

export default {
  state: {
    pages: [],
    page: null,
  },

  getters: {
    pages(state) {
      return state.pages;
    },
    page(state) {
        return state.page
    }
  },

  actions: {
    async getPages({ commit }, payload) {
      await globalAxios
        .post("/page/all", payload)
        .then((res) => {
          console.log("pages:", res.data.data);
          commit("setPages", res.data.data);
        })
        .catch((error) => console.log(error));
    },

    async delPage(_, payload) {
      await globalAxios
        .post("/page/del", payload)
        .then((res) => {
          console.log("del:", res.data.data);
        })
        .catch((error) => console.log(error));
    },

    async getPageDetail({commit}, payload) {
        await globalAxios.post('/page/id', payload)
        .then(res => {
            commit('setPage', res.data.data)
        })
        .catch(error => console.log(error))
    },

    async createPage(_, payload) {
        await globalAxios.post('/page/create', payload)
        .then(res => {
            console.log('savepage:', res.data.data)
        })
        .catch(error => console.log(error))
    },

    async updatePage(_, payload) {
        await globalAxios.post('/page/update', payload)
        .then(res => {
            console.log('update:', res.data.meta.message)
        })
        .catch(error => console.log(error))
    }
  },

  mutations: {
    setPages(state, payload) {
      state.pages = payload;
    },
    setPage(state, payload) {
        state.page = payload
    }
  },
};
