<template>
    <v-app>
      <top-navigation></top-navigation>
      <left-navigation></left-navigation>
      <!-- Sizes your content based upon application components -->
      <v-main>

        <!-- Provides the application the proper gutter -->
        <v-container fluid>

          <!-- If using vue-router -->
          <router-view></router-view>
        </v-container>
      </v-main>
      
      <footer-dashboard></footer-dashboard>
    </v-app>
</template>

<script>
import { LeftNavigation, TopNavigation, FooterDashboard } from './Dashboard'

export default {
  components: {
    LeftNavigation,
    TopNavigation,
    FooterDashboard
  },
  setup() {
    
  },
}
</script>