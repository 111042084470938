<template>
    <v-container class="pt-7 pb-2">
        <v-row>
            <v-col lg="6" cols="12">

                <div
                    v-if="frontSettings"
                >
                  <h2 class="intro-text mt-5 welcomeText">{{frontSettings.title}}</h2>
                  <div class="description" v-html="frontSettings.welcome"></div>
                  <v-btn color="success" class="white--text mr-10 mb-2" rounded to="/products">Our Products</v-btn>
                  <v-btn color="indigo" class="white--text mb-2" rounded to="contact">
                      <v-icon>mdi-play-circle</v-icon> Contact Us
                  </v-btn>
                </div>
                <v-skeleton-loader
                    v-else
                    v-bind="attrs"
                    type="article, actions"
                ></v-skeleton-loader>
            </v-col>
            <v-col lg="6" table-headingcols="12">
                <img width="100%"
                     v-if="frontSettings"
                     :src="`https://ex.exhotelbali.com/storage/${frontSettings.banner}`"
                >
                <v-skeleton-loader
                    v-else
                    v-bind="attrs"
                    type="card-avatar"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
  import {mapGetters} from 'vuex'
    export default {
      name: "HeroComponent",
      data () {
          return {
            loading: true,
          }
      },

      computed: {
        ...mapGetters(['frontSettings'])
      }
    }
</script>

<style scoped>
    h2.intro-text {
        font-size: 60px;
        font-weight: bold;
        color: #444;
        line-height: 68px;
    }
    p.description {
        font-size: 19px;
        margin: 15px 0px;
        color: #444444ba;
    }
</style>