import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from './Components/Layout'
import FrontLayout from './Components/Layout/FrontLayout'

import VueMeta from 'vue-meta'

// import store from './Store/index'

import {
    Products,
    Users,
    Settings,
    NotFound,
    Login,
    Dashboard,
    UpdateUser,
    ProductUpdate,
    CategoriesList,
    PagesList,
    PageAdd,
    PageUpdate,
} from './Components/Pages/index'

import {
    HomePage,
    AllProductsPage,
    ProductDetail,
    AboutUs,
    ContactUs,
} from './Components/Frontend'

import {
    ProductPopular,
    ProductCategory,
    ProductSold,
    ProductSearch
} from './Components/Molecules'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
    { 
        path:'/', 
        component: FrontLayout,
        children: [
            { path:'/', component: HomePage, name: 'home' },
            { path:'/product/:slug', component: ProductDetail, name: 'productdetail' },
            { path:'/products', component: AllProductsPage, name: 'products' },
            { path:'/products/new', component: AllProductsPage, name: 'products' },
            { path:'/sold', component: ProductSold, name: 'SoldOut' },
            { path:'/search', component: ProductSearch, name: 'search' },
            { path:'/products/best', component: ProductPopular, name: 'ProductPopular' },
            { path:'/category/:slug', component: ProductCategory, name: 'ProductCategory' },
            { path:'/about', component: AboutUs, name: 'about' },
            { path:'/contact', component: ContactUs, name: 'contact' }
        ]
    },

    { path:'/login', component: Login, name: 'Login' },
    { 
        path:'/dashboard', 
        component: Layout,
        children: [
            {
                path:'main',
                component: Dashboard,
                meta: { auth: true },
                name: 'Dashboard'
            },
            { 
                path:'products', 
                component: Products,
                meta: { auth: true },
                name: 'Products',
            },
            {
                path: 'product/edit/:id',
                component: ProductUpdate,
                meta: { auth: true },
                name: 'Update Product'
            },
            { 
                path:'pages', 
                component: PagesList,
                meta: { auth: true },
                name: 'Pages',
            },
            {
                path: 'page/add',
                component: PageAdd,
                meta: { auth: true },
                name: 'Add Page'
            },
            {
                path: 'page/edit/:id',
                component: PageUpdate,
                meta: { auth: true },
                name: 'Update Page'
            },
            { 
                path:'categories', 
                component: CategoriesList,
                meta: { auth: true },
                name: 'Categories',
            },
            {
                path: 'category/edit/:id',
                component: ProductUpdate,
                meta: { auth: true },
                name: 'Update Category'
            },
            { 
                path:'users', 
                component: Users,
                meta: { auth: true },
                name: 'Users',
            },
            {
                path: 'user/add',
                component: Users,
                meta: { auth: true },
                name: 'Add User'
            },
            {
                path: 'user/edit/:id',
                component: UpdateUser,
                meta: { auth: true },
                name: 'Update User'
            },
            { path:'settings', component: Settings, meta: { auth: true }, name: 'Setting' },
            { path:'/:notfound(.*)', component: NotFound, meta: { auth: true }, name: 'Not Found' },
        ],
    },
    
    
    
]


export default new VueRouter({mode: 'history', routes});