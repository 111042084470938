<template>
    <div>
        <v-row>
            <v-col md="4" cols="12">
                <v-carousel
                    cycle
                    height="400"
                    hide-delimiter-background
                    show-arrows-on-hover
                >
                    <v-carousel-item
                      v-for="(item,i) in frontProductSlider"
                      :key="i"
                      :src="`https://ex.exhotelbali.com/storage/${item.filename}`"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                    ></v-carousel-item>
                </v-carousel>
                
                <!-- <limited-product /> -->
            </v-col>
            <v-col md="5">
              <v-card-title class="pa-0 mb-4">{{frontProductDetail.name}}</v-card-title>
              <div v-html="frontProductDetail.description"></div>
            </v-col>
            <v-col md="3" cols="12">
                <v-card>
                    <v-card-text>
                        <h2 class="mb-2">IDR {{formatPrice(frontProductDetail.price)}}</h2>
                        <v-divider />
                        <v-divider class="mb-3" />
                        <h3 class="mb-2 subTitle">Code Product : EHB{{frontProductDetail.id}}</h3>
                        <h3 class="mb-2 subTitle">Bahan : {{frontProductDetail.bahan}}</h3>
                        <h3 class="mb-2 subTitle">Dimensi : {{frontProductDetail.dimensi}}</h3>
                        <v-divider class="mb-3" />
                        
                            <v-btn
                                block
                                color="primary mb-2"
                                :href="`https://wa.me/${formatPhone(frontSettings.phone)}?text=Hello Ex Hotel Bali`"
                                target="_blank"
                            >
                            Whatsapp
                            </v-btn>
                            
                            <v-btn
                                block
                                color="error mb-2"
                                target="_blank"
                                :href="`tel:${frontSettings.phone}`"
                            >
                            Call {{ frontSettings.phone }}
                            </v-btn>

                            <v-btn
                                block
                                color="secondary"
                                target="_blank"
                                :href="frontSettings.maps_link"
                            >
                              Address
                            </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>

// import LimitedProduct from '../LimitedProduct'
import {mapGetters} from 'vuex'

export default {
    data () {
      return {
        loading: false,
        colors: [
          'indigo',
          'warning',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
        ],
        title: "Ex Hotel Bali",
        description: "Ex Hotel Bali",
        keyword: "Ex Hotel Bali",
      }
    },

  created() {
      this.getFrontProductDetail()
      this.getFrontProductSlider()
    this.getMeta()
  },
  metaInfo() {
    return {
      title: `Ex Hotel Bali | ${this.title}`,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
        { name: 'keyword', content: this.keyword }
      ]
    }
  },

  computed: {
    ...mapGetters(['frontProductDetail', 'frontProductSlider', 'frontSettings'])
  },

  methods: {
    async getFrontProductDetail() {
      this.loading = true
      const formData = {
        slug: this.$route.params.slug
      }
      await this.$store.dispatch('getFrontProductDetail', formData)
      this.loading = false
      this.updateFrontViews()
    },

    async getFrontProductSlider() {
      this.loading = true
      const formData = {
        slug: this.$route.params.slug
      }
      await this.$store.dispatch('getFrontProductSlider', formData)
      this.loading = false
    },

    updateFrontViews() {
      const formData = {
        id: this.frontProductDetail.id,
        views: 1
      }
      this.$store.dispatch('updateFrontViews', formData)
    },

    formatPrice (value) {
      let val = (value/1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

    formatPhone (value) {
      let val = value.substring(0,1)
      if(val == '+') {
        return value.substr(1, value.length)
      }else{
        return value
      }
    },
    getMeta() {
      this.title = this.$store.getters.frontProductDetail.name,
          this.description = this.$store.getters.frontProductDetail.description,
          this.keyword = this.$store.getters.frontSettings.keyword
    }
  }
}
</script>

<style scoped>
    .subTitle {
        font-weight: normal;
    }
</style>