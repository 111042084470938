<template>
    <v-container>
        <v-row>
            <v-col md="2">
                <front-sidebar />
            </v-col>
            <v-col md="10">
                <v-row>
                    <v-col md="3"
                           v-for="item in frontProducts"
                           v-bind:key="item.id"
                    >
                      <column-product
                          :loading="loading"
                          :name="item.name"
                          :bahan="item.bahan"
                          :dimensi="item.dimensi"
                          :price="item.price"
                          :thumbnail="item.thumbnail"
                          :stock="item.stock"
                          :description="item.description"
                          :slug="item.slug"
                      ></column-product>
        </v-col>
        </v-row>
        <v-card-text class="d-flex justify-center mt-5">
            <v-btn color="primary" @click="loadMore()">Load More</v-btn>
        </v-card-text>
        </v-col>
        </v-row>

    </v-container>
</template>

<script>
    import {mapGetters} from 'vuex';
    import FrontSidebar from '../FrontSidebar'
    import { ColumnProduct } from '../../Atoms'
    export default {
      components: {
        FrontSidebar,
        ColumnProduct
      },
      data: () => ({
        loading: false,
        selection: 1,

        pagination: {
              page: 1,
              size: 12,
              sort: "views",
              direction: "desc"
        }
      }),

    created() {
        this.getProducts()
    },

      computed:{
        ...mapGetters(['frontProducts', 'totalFrontProducts'])
    },

    methods: {

      async getProducts() {
            this.loading = true
            const formData = {
                page: this.pagination.page,
                size: this.pagination.size,
                sort: this.pagination.sort,
                direction: this.pagination.direction,
                active: 1,
                stock: 1
            }
            await this.$store.dispatch('getFrontProductActive', formData)
            this.loading = false
        },

      async loadMore () {
            // console.log('load more')
            this.pagination.page = this.pagination.page + 1
            const formData = {
                page: this.pagination.page,
                size: this.pagination.size,
                sort: this.pagination.sort,
                direction: this.pagination.direction,
                active: 1,
                stock: 1
            }
            console.log('formData:', formData)
            await this.$store.dispatch('moreFrontProductActive', formData)
            this.loading = false
        },

      formatPrice (value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

      formatText (value) {
            let val = value.substring(0,100) + "..."
            return val
        }

        },
}
</script>

<style scoped>
    .btnShowMore {
        text-align: center;
        padding: 30px 0;
    }



</style>
