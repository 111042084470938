<template>
    <v-app class="loginContainer">
    <v-card
        class="loginCenter"
    >
        <v-card-text class="titleLogin">ExHotel</v-card-text>
        <v-card-text>
            <form @submit.prevent="onSubmit">
                <v-text-field
                v-model="email"
                type="email"
                label="E-mail"
                required
                ></v-text-field>
                <v-text-field
                v-model="password"
                type="password"
                label="Password"
                required
                ></v-text-field>
                
                <v-btn
                    class="mr-4"
                    color="primary"
                    block
                    :loading="loading"
                    :disabled="loading"
                    type="submit"
                >
                Login
                </v-btn>
            </form>
        </v-card-text>
    </v-card>
    </v-app>
</template>

<script>
export default {
    data () {
        return {
            email: '',
            password: '',
            loading: false,
        }
    },

    methods: {
        async onSubmit () {
            this.loading = true
            const formData = {
                email: this.email,
                password: this.password
            }
            // console.log(formData)
            await this.$store.dispatch('doLogin', formData)
            this.loading = false
        }
    }
}
</script>

<style scoped>
.loginCenter {
    margin-top: 100px !important;
    width: 400px;
    margin: 0 auto;
    padding: 25px 15px;
}
.titleLogin {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
}
</style>