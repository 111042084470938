<template>
    <v-card-text>
            <v-app-bar
                color="white"
                elevate-on-scroll
                shadow="none"
            >
                <v-toolbar-title>Products</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                   small
                    color="primary"
                    dark
                    @click="dialog = true"
                >
                Add New
                </v-btn>
            </v-app-bar>

            <v-data-table
                    :headers="headers"
                    :items="products"
                    :options.sync="pagination"
                    :server-items-length="totalProducts"
                    :footer-props="{
                      'items-per-page-options': [10, 25, 50, 100],
                      'items-per-page-text': 'Baris per Halaman:',
                    }"
                    :items-per-page="10"
                    :loading="loading"
                    :loading-text="loadingtext"
            >
              <template v-slot:item="props">
                <tr>
                      <td>EHB{{ props.item.id }}</td>
                      <td>{{ props.item.name }}</td>
                      <td>{{ props.item.bahan }}</td>
                      <td>{{ props.item.stock }}</td>
                      <td>{{ props.item.price }}</td>
                      <td>{{ props.item.active == 1 ? 'active' : 'In Active' }}</td>
                      <td>
                        <v-btn icon
                          :to="'product/edit/' + props.item.id"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon
                          @click="showDelete(props.item.id)"
                        >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </td>
                </tr>
              </template>
            </v-data-table>

            <!-- ADD DIALOG -->
      <template>
        <v-row justify="center">
          <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">Add product</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="title"
                        label="Title for your product"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="dialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="addTitleProduct()"
                >
                  Continue
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>

       <!-- DIALOG DEL -->
            <v-row justify="center">
              <v-dialog
                v-model="dialogDel"
                persistent
                max-width="290"
              >
                <v-card>
                  <v-card-title class="text-h5">
                    Are you sure ?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="dialogDel = false"
                    >
                      No
                    </v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="onDelete(delId)"
                    >
                      Yes
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
            
    </v-card-text>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    data () {
      return {
        active: 0,
        dialog: false,
        loading: false,
        dialogDel: false,
        loadingtext: '',
        delId: null,
        title: '',
        headers: [
          {
            text: 'IDReg',
            align: 'start',
            value: 'id',
          },
          { text: 'Title', value: 'title' },
          { text: 'Bahan', value: 'bahan' },
          { text: 'Stock', value: 'stock' },
          { text: 'Price', value: 'price' },
          { text: 'Active', value: 'active' },
          { text: 'Action', value: 'action' },
        ],
        pagination: {}
      }
    },


    // created () {
    //   this.getProducts()
    // },

    computed: {
      ...mapGetters(['products', 'totalProducts'])
    },

    watch: {
      pagination() {
        this.getProducts();
      }
    },

    methods: {
      async addTitleProduct () {

        this.loading = true

        const formData = {
          name: this.title,
          slug: this.title.toLowerCase().split(" ").join("-")
        }

        console.log('formData:', formData)

        await this.$store.dispatch('addTitleProduct', formData)

        this.loading = false
        this.dialog = false

      },

      async getProducts () {
        this.loading = true
        const formData = {
          page : this.pagination.page - 1,
          size : this.pagination.itemsPerPage,
          sort : "id",
          direction : "desc",
          active : this.active
        }
        // console.log(formData)
        await this.$store.dispatch('getProducts', formData)
        this.loading = false
      },

      async onDelete(id) {
        console.log(id)
        this.loading = true

        const formData = {
          id: id
        }

        await this.$store.dispatch('delProduct', formData)

        this.getProducts()

        this.dialogDel = false

        this.loading = false
        this.delId = null
      },

      showDelete (id) {
        this.delId = id
        this.dialogDel = true
      }
    }
  }
</script>