<template>
  <div>
    <v-card-text>
      <span class="text-h5">Update User</span>
    </v-card-text>
    <v-divider></v-divider>
    <v-form>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="user.name"
            name="name"
            label="Full Name"
            required
            outlined
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="user.email"
            name="email"
            label="Email"
            readonly
            outlined
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="user.password"
            name="password"
            label="Password"
            outlined
            type="password"
            :messages="['Kosongkan jika tidak ingin mengganti password']"
          ></v-text-field>
        </v-col>
        
      </v-row>
    </v-container>
    <v-btn
          class="ma-2"
          :loading="loading"
          :disabled="loading"
          color="primary"
          @click="onSubmit()"
        >
          Update
        </v-btn>
  </v-form>

  <!-- DIALOG SUCCESS -->
            <v-row justify="center">
              <v-dialog
                v-model="dialogSuccess"
                persistent
                max-width="290"
              >
                <v-card>
                  <v-card-title class="text-h5 center">
                    Update Success
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="dialogSuccess = false"
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      dialogSuccess: false,
      loading: false,
      name: '',
      email: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => v.length <= 10 || 'Name must be less than 10 characters',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
    }
  },

  created() {
    this.getUserDetail()
  },

  computed: {
    ...mapGetters(['user'])
  },
  
  methods: {
    async onSubmit () {
      this.loading = true
      const formData = {
        id: Number(this.$route.params.id),
        name: this.user.name,
        email: this.user.email,
        active: 1,
        password: this.user.password ? this.user.password : ""
      }
      await this.$store.dispatch('saveUserDetail', formData)

      this.dialogSuccess = true
      
      this.loading = false
    },

    async getUserDetail () {
      this.loading = true
      const formData = {
        id: Number(this.$route.params.id),
      }

      await this.$store.dispatch('getUserDetail', formData)
      this.loading = false
    }
  }
}
</script>