<template>
    <v-container>
        <v-row>
            <v-col md="6" cols="12">
                 <v-card-title class="pa-0">{{frontPageAbout.name}}</v-card-title>
                <div class="text-justify" v-html="frontPageAbout.description"></div>
            </v-col>
            <v-col md="6" cols="12">
                <v-card-title class="pa-0">{{ frontPageHow.name }}</v-card-title>
                 <div class="text-justify" v-html="frontPageHow.description"></div>
            </v-col>
        </v-row>
       
        <v-divider class="mt-5 mb-5" />
        

        <v-row>
            <v-col md="4">
                <v-card
                    class="mx-auto text-center"
                    outlined
                >
                    <v-list-item three-line>
                    <v-list-item-content>
                        <div class="text-overline mb-4">
                        {{frontSettings.title}}
                        </div>
                        <v-list-item-title class="text-h5 mb-1">
                        Contact via Whatsapp
                        </v-list-item-title>
                    </v-list-item-content>

                    </v-list-item>

                    <v-card-actions class="justify-center">
                    <v-btn
                        outlined
                        rounded
                        text
                        class="pr-2"
                    >
                      {{frontSettings.phone}}
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
             <v-col md="4">
                <v-card
                    class="mx-auto text-center"
                    outlined
                >
                    <v-list-item three-line>
                    <v-list-item-content>
                        <div class="text-overline mb-4">
                          {{frontSettings.title}}
                        </div>
                        <v-list-item-title class="text-h5 mb-1">
                        Contact via Email
                        </v-list-item-title>
                    </v-list-item-content>

                    </v-list-item>

                    <v-card-actions class="justify-center">
                    <v-btn
                        outlined
                        rounded
                        text
                        class="pr-2 text-lowercase"
                    >
                      {{frontSettings.email}}
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
             <v-col md="4">
                <v-card
                    class="mx-auto text-center"
                    outlined
                >
                    <v-list-item three-line>
                    <v-list-item-content>
                        <div class="text-overline mb-4">
                          {{frontSettings.title}}
                        </div>
                        <v-list-item-title class="text-h5 mb-1">
                        Call Us
                        </v-list-item-title>
                    </v-list-item-content>

                    </v-list-item>

                    <v-card-actions class="justify-center">
                    <v-btn
                        outlined
                        rounded
                        text
                        class="pr-2"
                    >
                      {{frontSettings.phone}}
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-divider class="mt-5 mb-5" />
<!--        Google Maps-->
        <div v-html="frontSettings.maps"></div>
    </v-container>
</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    data () {
      return {
        loading: false,
      }
    },

    computed: {
      ...mapGetters(['frontSettings', 'frontPageAbout', 'frontPageHow'])
    },

    created() {
      this.getFrontPageAbout()
      this.getFrontPageHow()
    },

    methods: {
      async getFrontPageAbout () {
        const formData = {
          slug: 'about-us'
        }
        await this.$store.dispatch('getFrontPageAbout', formData)
      },

      async getFrontPageHow () {
        const formData = {
          slug: 'how-to-order'
        }
        await this.$store.dispatch('getFrontPageHow', formData)
      }
    }
  }
</script>