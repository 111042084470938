<template>
    <v-app-bar
        app
      dense
      dark
    >
      <v-app-bar-nav-icon @click.stop="setDrawer()"></v-app-bar-nav-icon>

      <v-toolbar-title>ExHotel</v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- <v-btn icon>
        <v-icon>mdi-account-circle</v-icon>
      </v-btn> -->

      <v-btn icon
        @click="onLogout"
      >
        <v-icon>mdi-logout</v-icon>
      </v-btn>

      
    </v-app-bar>
</template>

<script>
export default {
  methods: {
    async onLogout () {
      await this.$store.dispatch('logout')
    },

    setDrawer() {
      this.$store.dispatch('setDrawer')
    }
  }
}
</script>

