<template>

    <v-navigation-drawer
        d-sm-none
      v-model="drawer"
      app
      fixed
      class="navLeft"

    >
       
        <v-list-item>
            <v-img src="../../../Assets/logo.png" width="100px" class="mt-5"></v-img>
        </v-list-item>
      <v-list-item>
        <v-text-field
            v-model="search"
            label="Search your idea here ..."
            outlined
            dense
            class="mt-6"
        >
          <v-icon
              slot="append"
              color="black"
              @click="onSearch()"
          >
            mdi-magnify
          </v-icon>
        </v-text-field>
      </v-list-item>
            <v-divider class="mt-2 mb-2" />

            <v-list-item link v-for="(menu,index) in menus" :key="index" :to="menu.route">
              <v-list-item-title>{{menu.title}}</v-list-item-title>
            </v-list-item>
      
    </v-navigation-drawer>

  
</template>

<script>
export default {
    props: ["drawer"],
    data () {
        return {
            // drawer: false
          menus:[
            {title:'Home',      route:'/'},
            {title:'Our Products', route:'/products'},
            {title:'Sold Out',  route:'/sold'},
            {title:'Contacts', route:'/contact'},
          ],
          search: "",
          pagination: {
            page: 0,
            size: 12,
            sort: "views",
            direction: "desc",
            id: 0,
            stock: 1,
            search: ""
          }
        }
    },
  methods: {
    async onSearch () {
      this.pagination.search = ""
      this.pagination.id = 0
      // console.log('Search:', this.search)
      let word = this.search.toUpperCase()
      word = word.toString()
      word = word.substring(0, 3)
      if(word == 'EHB') {
        let total = this.search.length
        let id = this.search.substr(3, total)
        this.pagination.id = parseInt(id)
      }else{
        this.pagination.search = this.search
      }

      const formData = {
        page: this.pagination.page,
        size: this.pagination.size,
        sort: this.pagination.sort,
        direction: this.pagination.direction,
        active: 1,
        stock: 1,
        id: this.pagination.id,
        search: this.pagination.search
      }

      await this.$store.dispatch('getFrontSearch', formData)
      this.drawer = false
    }
  }
}
</script>

<style scoped>
/* .navLeft {
    max-height: 200px;
    overflow:visible;
} */
.items {
  /*max-height: 200px;*/
  /*overflow: auto;*/
}
</style>