<template>
    <v-card-text>
        <v-progress-linear
            v-if="spinner"
            indeterminate
            color="yellow darken-2"
          ></v-progress-linear>
        <v-form>
            <v-container>
            <v-row>
                
                <v-col cols="12" md="8">
                    <v-text-field
                        v-model="product.name"
                        name="title"
                        label="Title"
                        required
                        outlined
                        dense
                    ></v-text-field>
                    <p class="mb-5">/{{product.slug}}</p>
                    <v-row>
                        <v-col sm="6">
                            <v-text-field
                            v-model="product.bahan"
                            name="bahan"
                            label="Bahan"
                            required
                            outlined
                            dense
                        ></v-text-field>
                        </v-col>
                        <v-col sm="4">
                          <v-text-field
                              v-model="product.dimensi"
                              name="dimensi"
                              label="Dimensi"
                              outlined
                              dense
                          ></v-text-field>
                        </v-col>
                        <v-col sm="2">
                            <v-text-field
                                v-model="product.stock"
                                name="stock"
                                label="Stock"
                                required
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <vue-editor 
                        v-model="product.description"
                       name="description"
                    ></vue-editor>

                </v-col>
                <v-col cols="12" md="4">
                    
                    <v-btn
                        class="mb-5"
                        :loading="loading"
                        :disabled="loading"
                        color="primary"
                        block
                        @click="onSave()"
                    >
                        Publish
                    </v-btn>
                    <v-btn
                        class="mb-5"
                        :loading="loading"
                        :disabled="loading"
                        color="secondary"
                        block
                        @click="onDraft()"
                    >
                        Draft
                    </v-btn>

                    <v-divider></v-divider>
                    <v-spacer></v-spacer>

                    <v-card-text>
                      <v-text-field
                        v-model="product.price"
                        name="price"
                        label="Price / ex: 99000"
                        required
                        outlined
                        dense
                    ></v-text-field>

                    <v-btn
                        class="mb-5"
                        :loading="loading"
                        :disabled="loading"
                        color="primary"
                        block
                        outlined
                        @click="showDialogCategory()"
                    >
                        Add Category
                    </v-btn>

                    <v-list-item
                        v-for="item in categoryProduct"
                        :key="item.id"
                      >

                        <v-list-item-content>
                          <v-list-item-title v-text="item.name"></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-btn icon
                            @click="delCategPost(item.id)"
                          >
                            <v-icon color="grey lighten-1">mdi-close</v-icon>
                          </v-btn>
                        </v-list-item-action>

                    </v-list-item>



                    <v-divider></v-divider>
                    <v-spacer></v-spacer>
                      <v-btn
                          class="mb-5"
                          :loading="loading"
                          :disabled="loading"
                          color="primary"
                          block
                          outlined
                          @click="showDialogSlider(image=0)"
                      >
                        Add Thumbnail
                      </v-btn>
                      <v-img
                          :v-if="product.thumbnail"
                          :lazy-src="`https://ex.exhotelbali.com/storage/${product.thumbnail}`"
                          max-height="150"
                          max-width="250"
                          :src="`https://ex.exhotelbali.com/storage/${product.thumbnail}`"
                      ></v-img>

                      <v-divider class="mt-5" />
                      <v-spacer />

                    <v-btn
                        class="mb-5"
                        :loading="loading"
                        :disabled="loading"
                        color="primary"
                        block
                        outlined
                        @click="showDialogSlider(image=1)"
                    >
                        Add Slider
                    </v-btn>
                    
                    <v-col v-for="item in sliderProduct" v-bind:key="item.id">
                      
                        <v-btn
                          icon
                          color="pink"
                          
                          small
                          class="float-right"
                          @click="delSliderProduct(item.id)"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        
                        <v-img 
                            :lazy-src="`https://ex.exhotelbali.com/storage/${item.filename}`"
                            max-height="150"
                            max-width="250"
                            :src="`https://ex.exhotelbali.com/storage/${item.filename}`"
                        ></v-img>
                        
                    </v-col>

                    </v-card-text>

                </v-col>
            
            </v-row>
            </v-container>
          <!-- ADD DIALOG -->
      <template>
        <v-row justify="center">
          <v-dialog
            v-model="dialog"
            scrollable
            max-width="70%"
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">Add Image</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-file-input
                            label="File input"
                            outlined
                            v-model="file"
                            dense
                            @change="uploadImage()"
                        ></v-file-input>

                    </v-col>
                  </v-row>
                    <v-progress-linear
                      indeterminate
                      color="yellow darken-2"
                      v-if="reload"
                    ></v-progress-linear>

                  <v-row>
                    <v-col md="3" v-for="item in sliders" v-bind:key="item.id">
                      <v-img 
                        :lazy-src="`https://ex.exhotelbali.com/storage/${item.filename}`"
                        max-height="150"
                        max-width="250"
                        :src="`https://ex.exhotelbali.com/storage/${item.filename}`"
                        @click="clickImage(item.id, item.filename, dialogType)"
                      ></v-img>
                    </v-col>
                  </v-row>

                </v-container>
              </v-card-text>
             <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  color="blue darken-1 float-right"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="dialog = false"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>


        <!-- DIALOG CATEGORY -->
        <v-row justify="center">
          <v-dialog
            v-model="dialogCategory"
            scrollable
            max-width="500px"
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">Add Category</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="10"
                    >
                      <v-text-field
                          v-model="category"
                          label="Category"
                          dense
                          required
                          outlined
                        ></v-text-field>

                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <v-btn 
                        depressed
                         color="primary"
                         @click="addCategory()"
                         >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>

                    </v-col>
                  </v-row>
                    <v-progress-linear
                      indeterminate
                      color="yellow darken-2"
                      v-if="reload"
                    ></v-progress-linear>

                  <v-row>
                    <v-col md="12">
                      <v-list
                        subheader
                        two-line
                      >

                        <v-list-item
                          v-for="itemCateg in categories"
                          :key="itemCateg.ID"
                        >

                          <v-list-item-content>
                            <v-list-item-title v-text="itemCateg.Name"></v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-btn icon
                              @click="addCategProduct(itemCateg.ID)"
                            >
                              <v-icon color="grey lighten-1">mdi-plus</v-icon>
                            </v-btn>
                          </v-list-item-action>

                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>

                </v-container>
              </v-card-text>
             <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  color="blue darken-1 float-right"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="dialogCategory = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>

        <!-- DIALOG SUCCESS -->
            <v-row justify="center">
              <v-dialog
                v-model="dialogSuccess"
                persistent
                max-width="290"
              >
                <v-card>
                  <v-card-title class="text-h5 center">
                    Update Success
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="dialogSuccess = false"
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>

      </template>

        </v-form>   
         
    </v-card-text>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { mapGetters } from 'vuex';

export default {
    components: {
        VueEditor
    },
    data () {
        return {
          loading: false,
          dialog: false,
          dialogSuccess: false,
          title:'',
          file: null,
          items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
          reload: false,
          content: '',
          imagID: null,
          spinner: false,
          dialogCategory: false,
          category: '',
          dialogThumb: false,
          dialogType: 0,
          
        }
    },

    created() {
      this.getProductDetail()
      this.getSliderProduct()
      this.getCategoryProduct()
    },

    computed: {
      ...mapGetters(['product', 'sliders', 'sliderProduct', 'categories', 'categoryProduct'])
    },

    methods: {

      async onSave() {
        this.spinner = true
        // console.log('detailsave:', this.product.name)
        const formData = {
          id: Number(this.$route.params.id),
          name: this.product.name,
          slug: this.product.slug,
          bahan: this.product.bahan,
          dimensi: this.product.dimensi,
          stock: Number(this.product.stock),
          price: Number(this.product.price),
          active: 1,
          description: this.product.description
        }
        console.log('form:', formData)
        await this.$store.dispatch('saveProduct', formData)

        this.getProductDetail()
        this.spinner = false
        this.dialogSuccess = true
      },

      async onDraft(){
        this.spinner = true
        // console.log('detailsave:', this.product.name)
        const formData = {
          id: Number(this.$route.params.id),
          name: this.product.name,
          slug: this.product.slug,
          bahan: this.product.bahan,
          dimensi: this.product.dimensi,
          stock: Number(this.product.stock),
          price: Number(this.product.price),
          active: 0,
          description: this.product.description
        }
        console.log('form:', formData)
        await this.$store.dispatch('saveProduct', formData)

        this.getProductDetail()
        this.spinner = false
        this.dialogSuccess = true
      },

      async getProductDetail() {
        this.spinner = true
        const formData = {
          id: Number(this.$route.params.id),
        }
        await this.$store.dispatch('getProductDetail', formData)
        this.spinner = false
      },

      showDialogSlider(image) {
        // console.log(image)
        this.dialogType = image
        this.dialog = true
        this.reload = true
        this.getAllSlider()
        this.reload = false
      },

      showDialogThumbnail(){
        this.dialogThumb = true
        this.reload = true
        this.getAllSlider()
        this.reload = false
      },

      async getAllSlider() {
        this.reload = true
        await this.$store.dispatch('getAllSliders')
        this.reload = false
      },

      async getAllCategory() {
        this.reload = true
        await this.$store.dispatch('getCategories')
        this.reload = false
      },

      async uploadImage() {
          this.reload = true
          // const formData = {
          //   file: this.file,
          // }
          const formData = new FormData();
          formData.append('file', this.file);

          // console.log('payload:', formData)
          await this.$store.dispatch('addImageSlider', formData)
          
          this.reload = true
          this.getAllSlider()
          this.reload = false

          this.reload = false
      },
      async clickImage(id, filename, dialogType) {
        if (dialogType == 1) {
          const formData = {
            product_id: Number(this.$route.params.id),
            slider_id: id
          }
          await this.$store.dispatch('createSliderRelation', formData)

          this.dialog = false
          this.getSliderProduct()
        }else{
          const formData = {
            id: Number(this.$route.params.id),
            thumbnail: filename
          }

          await this.$store.dispatch('updateThumbnail', formData)

          this.dialog = false
          this.getProductDetail()
        }
        
      },
      async clickImageThumb(filename) {
        const formData = {
          id: Number(this.$route.params.id),
          thumbnail: filename
        }

        await this.$store.dispatch('updateThumbnail', formData)

        this.dialog = false
        this.getProductDetail()
      },

      async getSliderProduct(){
        // console.log('id', this.$route.params.id)
        const formData = {
          id: Number(this.$route.params.id),
        }
        
        await this.$store.dispatch('getSliderByProduct', formData)
        
      },

      async delSliderProduct (id) {
        const formData = {
          slider_id: id,
          product_id: Number(this.$route.params.id)
        }

        await this.$store.dispatch('delSliderProduct', formData)

        this.getSliderProduct()
      },

      showDialogCategory () {
        this.dialogCategory = true
        this.getAllCategory()
        // this.dialogCategory = false
      },

      async addCategory() {
        this.reload = true
        const formData = {
          name: this.category
        }
        await this.$store.dispatch('addCategory', formData)
        this.getAllCategory()
        this.reload = false
      },

      async addCategProduct(id){
        this.reload = true
        const formData = {
          product_id: Number(this.$route.params.id),
          category_id: id,
        }
        await this.$store.dispatch('addCategProduct', formData)
        this.getCategoryProduct()
        this.reload = false
      },

      async getCategoryProduct() {
        this.reload = true
        const formData = {
          id: Number(this.$route.params.id),
        }
        this.$store.dispatch('getCategoryProduct', formData)
        this.reload = false
      },

      async delCategPost(id) {
        this.spinner = true
        const formData = {
          product_id: Number(this.$route.params.id),
          category_id: id
        }
        // console.log('formData:', formData)
        await this.$store.dispatch('delCategPost', formData)
        this.getCategoryProduct()
        this.spinner = false
      }
    }
}
</script>