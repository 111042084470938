<template>
  <div>
    <v-card-text>
      <span class="text-h5">Settings</span>
    </v-card-text>
    <v-divider></v-divider>
    <v-progress-linear
            v-if="loading"
            indeterminate
            color="yellow darken-2"
          ></v-progress-linear>
    <v-form>
    <v-container>
      
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="setting.title"
            name="name"
            label="Website Title"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="setting.keyword"
            name="email"
            label="Website Keyword / e.g. Fashion, Table"
            dense
            outlined
          ></v-text-field>
        </v-col>

        
      </v-row>
      <v-text-field
            v-model="setting.description"
            name="email"
            counter="100"
            label="Website Description"
            dense
            outlined
        ></v-text-field>
    <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="setting.facebook"
            name="name"
            label="Facebook Link"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="setting.instagram"
            name="email"
            label="Instagram Link"
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-text-field
            v-model="setting.maps"
            name="email"
            label="Google Frame"
            dense
            outlined
        ></v-text-field>
      <v-text-field
          v-model="setting.maps_link"
          name="email"
          label="Maps Link"
          dense
          outlined
      ></v-text-field>
        <v-text-field
            v-model="setting.address"
            name="email"
            label="Address"
            dense
            outlined
        ></v-text-field>
      <v-row>
        <v-col
            cols="12"
            md="6"
        >
          <v-text-field
              v-model="setting.phone"
              name="email"
              label="Wa Number / e.g.+6281338123456"
              dense
              outlined
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
          <v-text-field
              v-model="setting.email"
              name="email"
              label="Email"
              dense
              outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mb-5">
        <v-col lg="6">
          <v-file-input
              label="Banner"
              outlined
              v-model="banner"
              dense
              @change="uploadImage()"
          ></v-file-input>
          <v-img
              :src="`https://ex.exhotelbali.com/storage/${setting.banner}`"
              width="100%">
          </v-img>
        </v-col>
        <v-col lg="6">
          <vue-editor
              v-model="setting.welcome"
              name="Welcome"
          ></vue-editor>
        </v-col>
      </v-row>

    </v-container>
      <v-btn
          class="ma-2 mb-10"
          :loading="loading"
          :disabled="loading"
          color="primary"
          @click="onSubmit()"
      >
        Update
      </v-btn>

        <!-- <v-divider></v-divider> -->

  </v-form>

  <!-- DIALOG SUCCESS -->
            <v-row justify="center">
              <v-dialog
                v-model="dialogSuccess"
                persistent
                max-width="290"
              >
                <v-card>
                  <v-card-title class="text-h5 center">
                    Update Success
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="dialogSuccess = false"
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { VueEditor } from 'vue2-editor';
export default {
    components: {
        VueEditor
    },
    data () {
      return {
        dialogSuccess: false,
        loading: false,
        banner: ''
      }
    },

    created() {
      this.getSetting()
    },

    computed: {
      ...mapGetters(['setting'])
    },

    methods: {
      async getSetting() {
        this.loading = true
        const formData = {
          id: 1
        }
        await this.$store.dispatch('getSetting', formData)
        this.loading = false
      },

      async onSubmit() {
        this.loading = true
        const formData = {
          id: 1,
          title: this.setting.title,
          keyword: this.setting.keyword,
          facebook: this.setting.facebook,
          instagram: this.setting.instagram,
          maps: this.setting.maps,
          address: this.setting.address,
          phone: this.setting.phone,
          description: this.setting.description,
          maps_link: this.setting.maps_link,
          email: this.setting.email,
          welcome: this.setting.welcome,
        }
        await this.$store.dispatch('updateSetting', formData)
        this.dialogSuccess = true
        this.getSetting()
        this.loading = false
      },

      async uploadImage() {
        this.reload = true
        // const formData = {
        //   file: this.file,
        // }
        const formData = new FormData();
        formData.append('banner', this.banner);

        // console.log('payload:', formData)
        await this.$store.dispatch('updateSettingBanner', formData)

        this.reload = true
        this.getSetting()
        this.reload = false

        this.reload = false
      },
    }
}
</script>