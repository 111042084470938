<template>
    <v-app>
        <!-- HEADER -->
        <nav-bar-home />
        <v-main class="mb-5 mt-5">
            <router-view :key="$route.fullPath"></router-view>
        </v-main>
        <footer-home />
    </v-app>
</template>
<script>
import { 
    NavBarHome,
    FooterHome
} from '../../Molecules'
export default {
    components: {
        NavBarHome,
        FooterHome
    },
    data () {
        return {

        }
    },

  created() {
      this.getSettings()
  },

  methods: {
    async getSettings(){
        const formData = {
          id : 1
        }
        await this.$store.dispatch('getFrontSettings', formData)
    }
  }
}
</script>