import globalAxios from "../../axios-global"

export default {
    state : {
        setting: null,
        drawer: false,
    },
    
    getters: {
        setting (state) {
            return state.setting
        },
        drawer: (state) => state.drawer,
    },
    
    actions: {
        
        async getSetting({commit}, payload) {
            await globalAxios.post('/setting/find', payload)
                    .then(res => {
                        console.log('setting:', res.data.data)
                        commit('setSetting', res.data.data)
                    })
                    .catch(error => console.log(error))
        },

        async updateSetting(_, payload) {
            await globalAxios.post('/setting/update', payload)
                    .then(res => {
                        console.log('setting:', res.data.data)
                    })
                    .catch(error => console.log(error))
        },

        async updateSettingBanner(_, payload) {
            await globalAxios.post('/setting/update/banner', payload)
                .then(res => {
                    console.log('setting:', res.data.data)
                })
                .catch(error => console.log(error))
        },

        setDrawer({
            commit,
            state
        }) {
            commit('setDrawer', !state.drawer)
        },
       
    },
    
    mutations: {
        setSetting (state, payload) {
            state.setting = payload
        },
        setDrawer(state, payload) {
            state.drawer = payload
            state.drawer = payload
        },
    },
}