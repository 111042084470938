import Vue from 'vue'
import Vuex from 'vuex'

import Auth from './Auth'
import Product from './Products'
import Setting from './Settings'
import Pages from './Pages'
import Front from './Front'

Vue.use(Vuex)

// const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        Auth,
        Product,
        Setting,
        Pages,
        Front
    },
})
