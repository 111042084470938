import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://ex.exhotelbali.com/api/v1'
})

// instance.defaults.headers.common['SOMETHING'] = 'something'
// instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// instance.defaults.headers.common['Content-Type'] = 'application/json';
// instance.defaults.headers.common['Accept'] = 'application/json';
// instance.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization, X-Requested-With,  Content-Type, Accept';
// instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default instance