<template>
  <div>
    <v-navigation-drawer
        floating
        permanent
        class="blue-grey lighten-5 accent-4 pa-0 navDrawer d-none d-sm-flex"

    >
      <v-list
          dense
          rounded
      >
        <v-list-item link to="/products">
          <v-list-item-content>
            <v-list-item-title>New Product</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/products/best">
          <v-list-item-content>
            <v-list-item-title>Popular Product</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider class="mb-5" />

      <h4 class="titleNavDrawer">Categories</h4>

      <v-list
          dense
          rounded
      >
        <v-list-item
            v-for="item in frontCategories"
            :key="item.ID"
            link
            :to="`/category/${item.Slug}`"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.Name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-row class="center d-flex d-sm-none">
      <v-expansion-panels accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>Categories</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list-item
                v-for="item in frontCategories"
                :key="item.ID"
                link
                :to="`/category/${item.Slug}`"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.Name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    data () {
      return {
        loading: false,
      }
    },

    created() {
      this.getFrontCategories()
    },

    computed: {
      ...mapGetters(['frontCategories'])
    },

    methods: {
      async getFrontCategories(){
        this.loading = true
        await this.$store.dispatch('getFrontCategories')
        this.loading = false

      }
    }
  }
</script>

<style scoped>
  .navDrawer {
    border-radius: 10px;
  }
  .titleNavDrawer {
    padding-left: 23px;
  }
</style>