<template>
  <v-container>
    <v-row>
      <v-col md="2">
        <front-sidebar />
      </v-col>
      <v-col md="10">
        <v-row>
          <v-col md="3"
                 v-for="item in frontProductCategory"
                 v-bind:key="item.id"
          >
            <column-product
                :loading="loading"
                :name="item.name"
                :bahan="item.bahan"
                :dimensi="item.dimensi"
                :price="item.price"
                :thumbnail="item.thumbnail"
                :stock="item.stock"
                :description="item.description"
                :slug="item.slug"
            ></column-product>
          </v-col>
        </v-row>
        <v-card-text class="d-flex justify-center mt-5">
          <v-btn color="primary" @click="loadMoreCategory()">Load More</v-btn>
        </v-card-text>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import {mapGetters} from 'vuex';
import FrontSidebar from '../FrontSidebar'
import { ColumnProduct } from '../../Atoms'

// import frontAxios from '../../../axios-front'

export default {
  components: {
    FrontSidebar,
    ColumnProduct
  },
  data: () => ({
    loading: false,
    selection: 1,
    query: [],
    totalQuery: 0,

    pagination: {
      page: 1,
      size: 12,
      sort: "views",
      direction: "desc",
      slug: '',
    }
  }),

  created() {
    this.getProductCategory()
  },

  computed:{
    ...mapGetters(['frontProductCategory', 'tfProductCategory'])
  },

  mounted() {
    this.getProductCategory()
  },

  methods: {

    async getProductCategory() {
      this.loading = true
      this.pagination.slug= this.$route.params.slug
      const formData = {
        page: this.pagination.page,
        size: this.pagination.size,
        sort: this.pagination.sort,
        direction: this.pagination.direction,
        slug: this.pagination.slug,
        stock: 1
      }
      console.log('formdata:', formData)
      await this.$store.dispatch('getFrontProductCategory', formData)
      
      // await frontAxios.post('/front/product/categ', formData)
      //           .then(res => {
      //               this.query = res.data.data
      //               this.totalQuery = res.data.meta.total
      //           })
      //           .catch(error => console.log(error))

      this.loading = false
    },

    async loadMoreCategory () {
      // console.log('load more')
      const formData = {
        page: this.pagination.page + 1,
        size: this.pagination.size,
        sort: this.pagination.sort,
        direction: this.pagination.direction,
        slug: this.pagination.slug,
        stock: 1
      }
      await this.$store.dispatch('moreFrontProductCategory', formData)
      this.loading = false
    },

    formatPrice (value) {
      let val = (value/1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

    formatText (value) {
      let val = value.substring(0,100) + "..."
      return val
    }

  },
}
</script>

<style scoped>
.btnShowMore {
  text-align: center;
  padding: 30px 0;
}
.priceProducts {
  font-size: 16px;
  font-weight: bold;
}
.bahanProducts {
  font-size: 12px;
  margin-bottom: 10px;
}
.titleProducts {
  padding-top: 5px;
  padding-bottom: 10px;
}


</style>
