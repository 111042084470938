import axios from '../../axios-auth'
import globalAxios from "../../axios-global"

import router from '../../router'

export default {
    state : {
        token: null,
        user: null,
        name: null,
        email: null,
        isLogin: false,
        users: [],
    },
    
    getters: {
        users (state) {
            return state.users
        },
        user (state) {
            return state.user
        },
        name (state) {
            return state.name
        },
        email (state) {
            return state.email
        }
    },
    
    actions: {
        async doLogin({commit}, authData) {
            await axios.post('/sessions', {
                email: authData.email,
                password: authData.password
            })
                .then(res => {
                    localStorage.setItem('token', res.data.data.token)
                    localStorage.setItem('user', res.data.data)
                    commit('authUser', {
                        isLogin: true,
                        token: res.data.data.token,
                        user: res.data.data
                    })

                    router.push('/dashboard/main')

                    // console.log('login:', res.data.data)
                    // commit('setUserId', res.data.data)
                })
                .catch(error => console.log(error))
        },

        async logout({commit}) {
            commit('clearAuthData')
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            router.replace('/login')
        },

        async tryAutoLogin({commit}) {
            const token = localStorage.getItem('token')
            if (!token) {
                return
            }

            const user = localStorage.getItem('user')
            commit('authUser', {
                token: token,
                user: user
            })
        },

        async getUsers({commit}) {
            await globalAxios.get('/users')
                .then(res => {
                    console.log('users:', res.data.data)
                    commit('setUsers', res.data.data)
                })
                .catch(error => console.log(error))
        },

        async delUser(_,payload) {
            await globalAxios.post('/user/delete', payload)
                .then(res => {
                    console.log('delData:', res.data)
                })
                .catch(error => console.log(error))
        },

        async addUser(_, payload) {
            await globalAxios.post('/register', payload)
                .then(res => {
                    console.log('addData:', res.data)
                })
                .catch(error => console.log(error))
        },

        async getUserDetail({commit}, payload) {
            await globalAxios.post('/user/detail', payload)
                .then(res => {
                    console.log('user:', res.data.data)
                    commit('setUser', res.data.data)
                })
                .catch(error => console.log(error))
        },

        async saveUserDetail(_, payload) {
            await globalAxios.post('/user/change', payload)
                .then(res => {
                    console.log('save:', res.data.data)
                })
                .catch(error => console.log(error))
        }
       
    },
    
    mutations: {
        authUser (state, userData) {
            state.isLogin = userData.isLogin,
            state.token = userData.token,
            state.user = userData.user
        },
        clearAuthData(state) {
            state.isLogin = false,
            state.token = null,
            state.user = null
        },
        setUsers(state, payload) {
            state.users = payload
        },
        setUser(state, payload) {
            state.user = payload
        }
    },
}