<template>
    <v-card-text>
        <v-app-bar
                color="white"
                elevate-on-scroll
                shadow="none"
            >
                <v-toolbar-title>Page Update</v-toolbar-title>
            </v-app-bar>
        <v-progress-linear
            v-if="spinner"
            indeterminate
            color="yellow darken-2"
          ></v-progress-linear>
        <v-form>
            <v-container>
            <v-row>
                
                <v-col cols="12" md="8">
                    <v-text-field
                        v-model="page.name"
                        label="Title"
                        required
                        outlined
                        dense
                    ></v-text-field>

                    <p class="mb-5">/{{page.slug}}</p>
                    
                    <vue-editor 
                        v-model="page.description"
                       name="description"
                    ></vue-editor>

                </v-col>
                <v-col cols="12" md="4">
                    
                    <v-btn
                        class="mb-5"
                        :loading="loading"
                        :disabled="loading"
                        color="primary"
                        block
                        @click="onSave()"
                    >
                        Save
                    </v-btn>


                </v-col>
            
            </v-row>
            </v-container>

           

            <!-- DIALOG SUCCESS -->
            <v-row justify="center">
              <v-dialog
                v-model="dialogSuccess"
                persistent
                max-width="290"
              >
                <v-card>
                  <v-card-title class="text-h5 center">
                    Update Success
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="dialogSuccess = false"
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>

      

        </v-form>   
         
    </v-card-text>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { mapGetters } from 'vuex';

export default {
    components: {
        VueEditor
    },
    data () {
        return {
          loading: false,
          dialog: false,
          dialogSuccess: false,
          spinner: false,
          reload: false,
          
        }
    },

    created(){
        this.getPageDetail()
    },

    computed: {
        ...mapGetters(['page'])
    },

    methods: {
        async getPageDetail() {
            this.spinner = true
            const formData = {
                id: Number(this.$route.params.id)
            }
            await this.$store.dispatch('getPageDetail', formData)
            // console.log(formData)
            this.spinner = false
        },

        async onSave() {
            this.spinner = true
            const formData = {
                id: Number(this.$route.params.id),
                name: this.page.name,
                slug: this.page.slug,
                description: this.page.description ? this.page.description : ''
            }
            await this.$store.dispatch('updatePage', formData)
            // console.log('detail:', formData)
            this.spinner = false
        }
    }
}
</script>