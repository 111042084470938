import globalAxios from "../../axios-global"

import router from '../../router'

import uploadAxios from "../../axios-upload"

export default {
    state : {
        products: [],
        totalProducts: 0,
        categories: [],
        sliders: [],
        sliderProduct: [],
        product: null,
        categoryProduct: []
    },
    
    getters: {
        products (state) {
            return state.products
        },
        totalProducts (state) {
            return state.totalProducts
        },
        categories (state) {
            return state.categories
        },
        sliders(state) {
            return state.sliders
        },
        sliderProduct(state) {
            return state.sliderProduct
        },
        product(state) {
            return state.product
        },
        categoryProduct(state) {
            return state.categoryProduct
        }
    },
    
    actions: {
        async addTitleProduct({commit}, payload) {
            await globalAxios.post('/product/create', payload)
                    .then(res => {
                        console.log('product:', res.data.data)
                        commit('setProducts', res.data.data)
                        router.push('product/edit/' + res.data.data.id)
                    })
                    .catch(error => console.log(error))
        },

        async getProducts({commit}, payload) {
            await globalAxios.post('/product', payload)
                .then(res => {
                    // console.log(res)
                    commit('setProducts', {
                        products: res.data.data,
                        totalProducts: res.data.meta.total
                    })
                })
                .catch(error => console.log(error))
        },

        async delProduct(_, payload) {
            await globalAxios.post('/product/del', payload)
                .then(res => {
                    console.log('del success', res.data.data)
                })
                .catch(error => console.log(error))
        },

        async getCategories({commit}) {
            await globalAxios.get('/category')
                .then(res => {
                    console.log('categ:', res.data.data)
                    commit('setCategories', res.data.data)
                })
                .catch(error => console.log(error))
        },

        async delCategory(_, payload) {
            await globalAxios.post('/category/delete', payload)
                .then(res => {
                    console.log('del success', res.data.data)
                })
                .catch(error => console.log(error))
        },

        async addCategory(_, payload) {
            await globalAxios.post('/category/register', payload)
                    .then(res => {
                        console.log('category:', res.data.data)
                    })
                    .catch(error => console.log(error))
        },

        async addCategProduct(_, payload) {
            await globalAxios.post('/categoryrelation/create', payload)
                            .then(res => {
                                console.log('Addcategory:', res.data.data)
                            })
                            .catch(error => console.log(error))
        },

        async getCategoryProduct({commit}, payload) {
            await globalAxios.post('/categoryrelation/get', payload)
                        .then(res => {
                            console.log('getCategoryProduct:', res.data.data)
                            commit('setCategoryProduct', res.data.data)
                        })
                        .catch(error => console.log(error))
        },

        async delCategPost(_, payload) {
            await globalAxios.post('/categoryrelation/del', payload)
                            .then(res => {
                                console.log('getCategoryProduct:', res.data.data)
                            })
                            .catch(error => console.log(error))
        },

        async getAllSliders({commit}) {
            await globalAxios.post('/slider/all')
                .then(res => {
                    console.log('sliders:', res.data.data)
                    commit('setSliders', res.data.data)
                })
                .catch(error => console.log(error))
        },

        async getProductDetail({commit}, payload) {
            await globalAxios.post('/product/detail', payload)
                .then(res => {
                    console.log('product:', res.data.data)
                    commit('setProduct', res.data.data)
                })
                .catch(error => console.log(error))
        },

        async saveProduct(_, payload) {
            await globalAxios.post('/product/update', payload)
                .then(res => {
                    console.log('post:', res.data.data)
                })
                .catch(error => console.log(error))
        },

        async getSliderByProduct({commit}, payload) {
            await globalAxios.post('/sliderrelation/get', payload)
                    .then(res => {
                        console.log('slider:', res.data.data)
                        commit('setSliderProduct', res.data.data)
                    })
                    .catch(error => {
                        console.log('res', error)
                        console.log('payload:', payload)
                    })
        },

        async createSliderRelation(_, payload) {
            await globalAxios.post('/sliderrelation/create', payload)
                    .then(res => {
                        console.log('res:', res.data.data)
                    })
                    .catch(error => console.log(error))
        },

        async updateThumbnail(_, payload) {
            await globalAxios.post('/product/thumb', payload)
                .then(res => {
                    console.log('res:', res.data.data)
                })
                .catch(error => console.log(error))
        },

        async delSliderProduct(_, payload) {
            await globalAxios.post('/sliderrelation/del', payload)
                    .then(res => {
                        console.log('res:', res.data.data)
                    })
                    .catch(error => console.log(error))
        },

        

        async addImageSlider({commit}, payload) {
            await uploadAxios.post('/slider/create', payload)
                    .then(res => {
                        commit('setSliders', res.data.data)
                        console.log('slider:', res.data)
                    })
                    .catch(res => {
                        console.log('res', res.data)
                    })
        },

        

        
       
    },
    
    mutations: {
        setProducts(state, payload) {
            state.products = payload.products,
                state.totalProducts = payload.totalProducts
        },
        setCategories(state, payload) {
            state.categories = payload
        },
        setSliders(state, payload) {
            state.sliders = payload
        },
        setSliderProduct(state, payload) {
            state.sliderProduct = payload
        },
        setProduct(state, payload) {
            state.product = payload
        },
        setCategoryProduct(state, payload) {
            state.categoryProduct = payload
        }
    },
}