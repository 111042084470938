<template>
    <v-footer padless>
        <v-container>
            <v-row justify="center">
                <v-col lg="4" class="text-center">
                    <v-img src="../../../Assets/logo.png" width="170px" class="mt-5 logoFooter"></v-img>
                    <v-card-text class="mb-5">
                        <v-btn class="mx-6 black--text" icon :href="frontSettings.facebook" target="_blank">
                            <v-icon size="30px">mdi-facebook</v-icon>
                        </v-btn>
                        <v-btn class="mx-6 black--text" icon :href="frontSettings.instagram" target="_blank">
                          <v-icon size="30px">mdi-instagram</v-icon>
                        </v-btn>
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row justify="center">
              <v-col lg="6" class="text-center">
                <!-- <v-subheader v-html="frontSettings.welcome"></v-subheader> -->
                <center><p>Call or whatsapp Wayan on +6281933116036</p></center>
              </v-col>
            </v-row>

            <div class="mt-10">
                <v-divider></v-divider>
                <v-subheader>
                    © Copyright ExHotelBali. All Rights Reserved {{ new Date().getFullYear() }}
                    <v-spacer />
                    develop by <a target="_blank" href="https://www.instagram.com/andrenatawayan/" style="color:#000; text-decoration: none">Andre Nata</a>
                </v-subheader>
            </div>
        </v-container>
    </v-footer>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "Footer",
        data() {
            return {
                icons: [
                    'mdi-facebook',
                    'mdi-instagram',
                ],
            }
        },
        computed: {
          ...mapGetters(['frontSettings'])
        }
    }
</script>

<style scoped>
    .logoFooter {
        margin:0 auto;
    }
</style>