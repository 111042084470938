<template>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
    <v-card
      height="100%"
    >
      
        <v-list>
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-icon>
                mdi-account
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="text-h6">
                Hello
              </v-list-item-title>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list
          nav
          dense
        >
          <v-list-item link to="/dashboard/main">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/dashboard/products">
            <v-list-item-icon>
              <v-icon>mdi-file</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Products</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/dashboard/pages">
            <v-list-item-icon>
              <v-icon>mdi-file</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Pages</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item link to="/dashboard/categories">
            <v-list-item-icon>
              <v-icon>mdi-file</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Categories</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/dashboard/products">
            <v-list-item-icon>
              <v-icon>mdi-file</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Sliders</v-list-item-title>
          </v-list-item> -->
          <v-list-item link to="/dashboard/users">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>User</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/dashboard/settings">
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>
        </v-list>
      
    </v-card>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    data () {
      return {
        // drawer: null,
      }
    },

    computed: {
      ...mapGetters(['drawer']),
    }
  }
</script>
