<template>
  <v-container>
    <div class="d-flex justify-space-between">
      <v-card-title class="pl-0">Popular Products</v-card-title>
      <v-spicer />
      <v-btn
          class="mt-5 btnMoreShow pr-0"
          to="/products/best"
          outlined
          color="secondary"
          small
      >
        More Products
        <v-icon small class="mr-1">mdi-arrow-right</v-icon>
      </v-btn>
    </div>
    <v-row>
      <v-col md="2"
             v-for="item in popular"
             v-bind:key="item.id"
      >
        <column-product
            :loading="loading"
            :name="item.name"
            :bahan="item.bahan"
            :dimensi="item.dimensi"
            :price="item.price"
            :thumbnail="item.thumbnail"
            :stock="item.stock"
            :description="item.description"
            :slug="item.slug"
        ></column-product>
      </v-col>
    </v-row>
    <v-divider class="mt-5"/>

  </v-container>
</template>

<script>
import { ColumnProduct } from '../../Atoms'
import {mapGetters} from "vuex";

export default {
  components: {
    ColumnProduct
  },
  data: () => ({
    loading: false,
    selection: 1,
    pagination: {
      page: 0,
      size: 12,
      sort: "views",
      direction: "desc"
    },
    popular: []
  }),

  created() {
    this.getProducts()
  },

  computed:{
    ...mapGetters(['frontProducts'])
  },

  methods: {
    async getProducts() {
      this.loading = true
      const formData = {
        page: this.pagination.page,
        size: this.pagination.size,
        sort: this.pagination.sort,
        direction: this.pagination.direction,
        active: 1,
        stock: 1,
      }
      await this.$store.dispatch('getFrontProductActive', formData)
      this.popular = this.frontProducts
      this.loading = false
    },

    formatPrice (value) {
      let val = (value/1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

    formatText (value) {
      let val = value.substring(0,100) + "..."
      return val
    }
  },
}
</script>

<style scoped>
.btnShowMore {
  text-align: center;
  padding: 30px 0;
}
.priceProducts {
  font-size: 16px;
  font-weight: bold;
}
.bahanProducts {
  font-size: 12px;
  margin-bottom: 10px;
}
.titleProducts {
  padding-top: 5px;
  padding-bottom: 10px;
}
</style>
