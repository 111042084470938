import frontAxios from "../../axios-front"

import router from '../../router'

export default {
    state : {
        frontProducts: [],
        totalFrontProducts: 0,
        frontProductDetail: '',
        frontProductSlider: [],
        frontCategories: [],
        frontProductCategory: [],
        tfProductCategory: 0,
        frontSettings: '',
        frontPageAbout: '',
        frontPageHow: '',
        frontSearch: [],
        totalFrontSearch: 0,
    },

    getters: {
        frontProducts (state) {
            return state.frontProducts
        },
        totalFrontProducts (state) {
            return state.totalFrontProducts
        },
        frontProductDetail(state) {
            return state.frontProductDetail
        },
        frontProductSlider(state) {
            return state.frontProductSlider
        },
        frontCategories(state) {
            return state.frontCategories
        },
        frontProductCategory(state) {
            return state.frontProductCategory
        },
        tfProductCategory(state) {
            return state.tfProductCategory
        },
        frontSettings(state) {
            return state.frontSettings
        },
        frontPageAbout(state) {
            return state.frontPageAbout
        },
        frontPageHow(state) {
            return state.frontPageHow
        },
        frontSearch(state) {
            return state.frontSearch
        },
        totalFrontSearch(state) {
            return state.totalFrontSearch
        }
    },

    actions: {

        async getFrontSettings({commit}, payload) {
            await frontAxios.post('/front/settings', payload)
                .then(res => {
                    commit('setFrontSettings', res.data.data)
                })
                .catch(error => console.log(error))
        },

        async getFrontPageAbout({commit}, payload) {
            await frontAxios.post('front/page/slug', payload)
                .then(res => {
                    commit('setFrontPageAbout', res.data.data)
                })
                .catch(error => console.log(error))
        },

        async getFrontPageHow({commit}, payload) {
            await frontAxios.post('front/page/slug', payload)
                .then(res => {
                    commit('setFrontPageHow', res.data.data)
                })
                .catch(error => console.log(error))
        },

        async getFrontProductActive({commit}, payload) {
            await frontAxios.post('/front/products', payload)
                .then(res => {
                    console.log('frontProducts:', res.data.data)
                    commit('setFrontProducts', {
                        frontProducts : res.data.data,
                        totalFrontProducts : res.data.meta.total
                    })
                })
                .catch(error => console.log(error))
        },

        async getFrontSearch({commit}, payload) {
            await frontAxios.post('/front/search', payload)
                .then(res => {
                    console.log('frontSearch:', res.data)
                    commit('setFrontSearch', {
                        frontSearch : res.data.data,
                        totalFrontSearch : res.data.meta.total
                    })
                    router.push('/search')
                })
                .catch(error => console.log(error))
        },

        async moreFrontProductActive({commit}, payload) {
            await frontAxios.post('/front/products', payload)
                .then(res => {
                    console.log('frontProducts:', res.data.data)
                    commit('loadFrontProducts', {
                        frontProducts : res.data.data,
                        totalFrontProducts : res.data.meta.total
                    })
                })
                .catch(error => console.log(error))
        },

        async getFrontProductDetail({commit}, payload) {
            await frontAxios.post('/front/product/detail', payload)
                .then(res => {
                    console.log('detail:', res)
                    commit('setFrontProductDetail', res.data.data)
                })
                .catch(error => console.log(error))
        },

        async getFrontProductSlider({commit}, payload) {
            await frontAxios.post('/front/product/slider', payload)
                .then(res => {
                    console.log('slider:', res)
                    commit('setFrontProductSlider', res.data.data)
                })
                .catch(error => console.log(error))
        },

        async getFrontCategories({commit}) {
            await frontAxios.post('/front/categories')
                .then(res => {
                    commit('setFrontCategories', res.data.data)
                })
                .catch(error => console.log(error))
        },

        // PRODUCT BY CATEG
        async getFrontProductCategory({commit}, payload) {
            await frontAxios.post('/front/product/categ', payload)
                .then(res => {
                    console.log('frontProductsCateg:', res.data.data)
                    commit('setFrontProductCategory', {
                        frontProductCategory : res.data.data,
                        tfProductCategory : res.data.meta.total
                    })
                })
                .catch(error => console.log(error))
        },
        async moreFrontProductCategory({commit}, payload) {
            await frontAxios.post('/front/product/categ', payload)
                .then(res => {
                    console.log('frontProductsCateg:', res.data.data)
                    commit('loadFrontProductCategory', {
                        frontProductCategory : res.data.data,
                        tfProductCategory : res.data.meta.total
                    })
                })
                .catch(error => console.log(error))
        },

        async updateFrontViews(_, payload) {
            await frontAxios.post('front/product/count/views', payload)
                .then(res => {
                    console.log('views:', res)
                })
                .catch(error => console.log(error))
        }

    },

    mutations: {
        setFrontSettings(state, payload) {
            state.frontSettings = payload
        },
        setFrontPageAbout(state, payload) {
            state.frontPageAbout = payload
        },
        setFrontPageHow(state, payload) {
            state.frontPageHow = payload
        },
        setFrontProducts (state, payload) {
            state.frontProducts = payload.frontProducts,
                state.totalFrontProducts = payload.totalFrontProducts

        },
        setFrontSearch (state, payload) {
            state.frontSearch = payload.frontSearch,
                state.totalFrontSearch = payload.totalFrontSearch

        },
        loadFrontProducts (state, payload) {
            if(!state.frontProducts){
                state.frontProducts = payload.frontProducts,
                    state.totalFrontProducts = payload.totalFrontProducts
            }else{
                state.frontProducts.push(...payload.frontProducts),
                    state.totalFrontProducts = payload.totalFrontProducts
            }

        },

        setFrontProductCategory (state, payload) {
            state.frontProductCategory = payload.frontProductCategory,
                state.tfProductCategory = payload.tfProductCategory

        },

        loadFrontProductCategory (state, payload) {
            if(!state.frontProductCategory){
                state.frontProductCategory = payload.frontProductCategory,
                    state.tfProductCategory = payload.tfProductCategory
            }else{
                state.frontProductCategory.push(...payload.frontProductCategory),
                    state.tfProductCategory = payload.tfProductCategory
            }

        },
        setFrontProductDetail(state, payload) {
            state.frontProductDetail = payload
        },
        setFrontProductSlider(state, payload) {
            state.frontProductSlider = payload
        },
        setFrontCategories(state, payload) {
            state.frontCategories = payload
        }

    },
}