<template>
    <v-card-text>
            <v-app-bar
                color="white"
                elevate-on-scroll
                shadow="none"
            >
                <v-toolbar-title>Categories</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                   small
                    color="primary"
                    dark
                    @click="dialog = true, addForm= true"
                >
                Add New
                </v-btn>
                <v-btn icon>
                    <v-icon>mdi-clipboard-text-search-outline</v-icon>
                </v-btn>
            </v-app-bar>

            <v-data-table
                    :headers="headers"
                    :items="categories"
                    :footer-props="{
                      'items-per-page-options': [10, 25, 50, 100],
                      'items-per-page-text': 'Baris per Halaman:',
                    }"
                    :items-per-page="10"
                    :loading="loading"
                    :loading-text="loadingtext"
            >
              <template v-slot:item="props">
                <tr>
                      <td>{{ props.item.ID }}</td>
                      <td>{{ props.item.Name }}</td>
                      <td>{{ props.item.Slug }}</td>
                      <td>
                        <v-btn icon
                          @click="showEdit(props.item.ID)"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon
                          @click="showDelete(props.item.ID)"
                        >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </td>
                </tr>
              </template>
            </v-data-table>

        <!-- ADD DIALOG -->
      <template>
        <v-row justify="center">
          <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
          >
            <v-card>
              <v-card-title>
                <span v-if="addForm" class="text-h5">Add Category</span>
                <span v-else class="text-h5">Update Category</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    
                      <v-text-field
                        v-if="addForm"
                        v-model="title"
                        label="Category Name"
                        required
                        outlined
                      ></v-text-field>
                      <v-text-field
                        v-else
                        v-model="getId"
                        label="Category Name"
                        required
                        outlined
                      ></v-text-field>
                    
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="dialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                    v-if="addForm"
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="addCategory()"
                >
                  Create
                </v-btn>
                <v-btn
                    v-else
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="updateCategory()"
                >
                  Update
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>

       <!-- DIALOG DEL -->
            <v-row justify="center">
              <v-dialog
                v-model="dialogDel"
                persistent
                max-width="290"
              >
                <v-card>
                  <v-card-title class="text-h5">
                    Are you sure ?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="dialogDel = false"
                    >
                      No
                    </v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="onDelete(delId)"
                    >
                      Yes
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
            
    </v-card-text>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    data () {
      return {
        dialog: false,
        loading: false,
        dialogDel: false,
        loadingtext: '',
        delId: null,
        addForm: false,
        title: '',
        getId: null,
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Categories', value: 'name' },
          { text: 'Slug', value: 'slug' },
          { text: 'Action' },
        ],
      }
    },


    created () {
      this.getCategories()
    },

    computed: {
      ...mapGetters(['categories'])
    },

    methods: {
      async addCategory () {

        this.loading = true

        const formData = {
          name: this.title,
        }

        console.log('formData:', formData)

        await this.$store.dispatch('addCategory', formData)

        this.getCategories()

        this.loading = false
        this.dialog = false

      },

      async getCategories () {
        this.loading = true
        await this.$store.dispatch('getCategories')
        this.loading = false
      },

      async onDelete(id) {
        console.log(id)
        this.loading = true

        const formData = {
          id: id
        }

        await this.$store.dispatch('delCategory', formData)

        this.getCategories()

        this.dialogDel = false

        this.loading = false
        this.delId = null
      },

      showDelete (id) {
        this.delId = id
        this.dialogDel = true
      },

      showEdit(id) {
          this.getId = id
          this.addForm = false

          this.dialog = true
      },

      updateCategory() {
          console.log(this.getId)
      }
    }
  }
</script>