<template>
    <div>
        <slider-home />
        <product-home />
        <product-home-popular />
        <footer-home />
    </div>
</template>
<script>
import { 
    SliderHome,
    ProductHome,
  ProductHomePopular
} from '../../Molecules'
// import {mapGetters} from "vuex";
export default {

    components: {
        ProductHome,
        SliderHome,
        ProductHomePopular
    },
    data () {
        return {
          title: "Ex Hotel Bali",
          description: "Ex Hotel Bali",
          keyword: "Ex Hotel Bali",
        }
    },

    created() {
      this.getMeta()
    },

    metaInfo() {
      return {
        title: this.title,
        meta: [
          { charset: 'utf-8' },
          { name: 'description', content: this.description },
          { name: 'keyword', content: this.keyword }
        ]
      }
    },

    methods: {
      getMeta() {
        this.title = this.$store.getters.frontSettings.title,
            this.description = this.$store.getters.frontSettings.description,
            this.keyword = this.$store.getters.frontSettings.keyword
      },

    }

}
</script>