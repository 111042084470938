<template>
    <v-card-text>
            <v-app-bar
                color="white"
                elevate-on-scroll
                shadow="none"
            >
                <v-toolbar-title>Pages</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                   small
                    color="primary"
                    dark
                    to="page/add"
                >
                Add New
                </v-btn>
            </v-app-bar>

            <v-data-table
                    :headers="headers"
                    :items="pages"
                    :footer-props="{
                      'items-per-page-options': [10, 25, 50, 100],
                      'items-per-page-text': 'Baris per Halaman:',
                    }"
                    :items-per-page="10"
                    :loading="loading"
                    :loading-text="loadingtext"
            >
              <template v-slot:item="props">
                <tr>
                      <td>{{ props.item.id }}</td>
                      <td>{{ props.item.name }}</td>
                      <td>{{ props.item.slug }}</td>
                      <td>
                        <v-btn icon
                          :to="'page/edit/' + props.item.id"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon
                          @click="showDelete(props.item.id)"
                        >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </td>
                </tr>
              </template>
            </v-data-table>

            

       <!-- DIALOG DEL -->
            <v-row justify="center">
              <v-dialog
                v-model="dialogDel"
                persistent
                max-width="290"
              >
                <v-card>
                  <v-card-title class="text-h5">
                    Are you sure ?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="dialogDel = false"
                    >
                      No
                    </v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="onDelete(delId)"
                    >
                      Yes
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
            
    </v-card-text>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    data () {
      return {
        dialog: false,
        loading: false,
        dialogDel: false,
        loadingtext: '',
        delId: null,
        title: '',
        headers: [
          {
            text: 'IDReg',
            align: 'start',
            value: 'id',
          },
          { text: 'Title', value: 'title' },
          { text: 'Slug', value: 'slug' },
          { text: 'Action', value: 'action' },
        ],
      }
    },


    created () {
      this.getPages()
    },

    computed: {
      ...mapGetters(['pages'])
    },

    methods: {
      async addTitlePage () {

        this.loading = true

        const formData = {
          name: this.title,
          slug: this.title.toLowerCase().split(" ").join("-")
        }

        console.log('formData:', formData)

        // await this.$store.dispatch('addTitlePage', formData)

        this.loading = false
        this.dialog = false

      },

      async getPages () {
        this.loading = true
        await this.$store.dispatch('getPages')
        this.loading = false
      },

      async onDelete(id) {
        console.log(id)
        this.loading = true

        const formData = {
          id: id
        }

        await this.$store.dispatch('delPage', formData)

        this.getPages()

        this.dialogDel = false

        this.loading = false
        this.delId = null
      },

      showDelete (id) {
        this.delId = id
        this.dialogDel = true
      }
    }
  }
</script>