<template>
    <v-card-text>
            <v-app-bar
                color="white"
                elevate-on-scroll
                shadow="none"
            >
                <v-toolbar-title>Users</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                   small
                    color="primary"
                    dark
                  @click="dialogAdd = true"
                >
                Add New
                </v-btn>
                <!-- <v-btn icon>
                    <v-icon>mdi-clipboard-text-search-outline</v-icon>
                </v-btn> -->
            </v-app-bar>

            <v-data-table
                    :headers="headers"
                    :items="users"
                    :footer-props="{
                      'items-per-page-options': [10, 25, 50, 100],
                      'items-per-page-text': 'Baris per Halaman:',
                    }"
                    :items-per-page="10"
                    :loading="loading"
                    :loading-text="loadingtext"
            >
              <template v-slot:item="props">
                <tr>
                      <td>{{ props.item.id }}</td>
                      <td>{{ props.item.name }}</td>
                      <td>{{ props.item.email }}</td>
                      <td>{{ props.item.active == 1 ? "Active" : "In Active" }}</td>
                      <td align="center">
                          <v-btn icon
                            :to="'user/edit/' + props.item.id"
                          >
                              <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        <v-btn icon
                          @click="dialogDel(props.item.id)"
                        >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>

                        
                        
                      </td>
                </tr>
               
              </template>
            </v-data-table>

            <!-- DIALOG DEL -->
            <v-row justify="center">
              <v-dialog
                v-model="dialog"
                persistent
                max-width="290"
              >
                <v-card>
                  <v-card-title class="text-h5">
                    Are you sure ?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="dialog = false"
                    >
                      No
                    </v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="onDelete(delId)"
                    >
                      Yes
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
      
      <!-- ADD DIALOG -->
      <template>
        <v-row justify="center">
          <v-dialog
            v-model="dialogAdd"
            persistent
            max-width="600px"
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">Add User</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="name"
                        :rules="nameRules"
                        label="Full Name"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="email"
                        :rules="emailRules"
                        label="Email"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="password"
                        type="password"
                        label="Password"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="dialogAdd = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="addUser()"
                >
                  Create
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
            
    </v-card-text>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    data () {
      return {
        loading: false,
        loadingtext: '',
        dialog: false,
        dialogAdd: false,
        id: null,
        delId: null,
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Name', value: 'name' },
          { text: 'Email', value: 'email' },
          { text: 'Status', value: 'active' },
          { text: 'Action', value: 'iron', align: 'center', },
        ],

        name: '',
        email: '',
        password: '',
        nameRules: [
          v => !!v || 'Name is required',
          v => v.length <= 10 || 'Name must be less than 10 characters',
        ],
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
      }
    },

    created () {
      this.getUsers()
    },

    computed: {
      ...mapGetters(['users'])
      // ...mapState(['users'])
    },

    methods: {
      async getUsers() {
        this.loading = true
        this.loadingtext = 'Please wait ...'

        await this.$store.dispatch('getUsers')
        this.loading = false
        this.loadingtext = ''
      },
      async onDelete(id) {
        // console.log('del:', id)
        this.loading = true
        this.loadingtext = 'Please wait ...'

        const formData = {
          id: id,
        }
        await this.$store.dispatch('delUser', formData)
        this.dialog = false
        this.getUsers()

        this.loading= false
      },
      async addUser() {
        this.loading = true
        
        const formData = {
          name: this.name,
          email: this.email,
          password: this.password,
        }

        await this.$store.dispatch('addUser', formData)
        this.delId = null

        this.getUsers()

        this.dialogAdd = false
        this.name = ''
        this.email = ''
        this.password = ''

        this.loading = false
      },
      dialogDel(id) {
        this.dialog = true
        this.delId = id
      }
      
    }
  }
</script>