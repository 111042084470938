<template>
    <v-container>
        <product-detail />
    </v-container>
</template>

<script>
import {
    ProductDetail,
} from '../../Molecules'
export default {
    components: {
        ProductDetail,
    },
    setup() {
        
    },
}
</script>