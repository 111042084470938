<template>
    <div>
        
    
    <v-app-bar
            app
            color="white"
            light
            elevation="6"
            elevate-on-scroll
    >
    <v-container>
        <v-row>
            <v-col cols="10" md="2" sm="12">
                <a href="https://exhotelbali.com/"><v-img src="../../../Assets/logo.png" width="180px" class="mt-6"></v-img></a>
            </v-col>
            <v-col class="d-none d-sm-flex" cols="10" md="5" sm="10">
              <v-text-field
                  v-model="search"
                  label="Search your idea here ..."
                  outlined
                  dense
                  class="mt-6"
              >
                <v-icon
                    slot="append"
                    color="black"
                    @click="onSearch()"
                >
                  mdi-magnify
                </v-icon>
              </v-text-field>
            </v-col>
            <v-col md="5" cols="12" class="d-none d-sm-flex">
                <v-list class="d-flex align-center navDrawerLeft">
                    <v-list-item link v-for="(menu,index) in menus" :key="index" :to="menu.route">
                        <v-list-item-title color="#dddddd">{{menu.title}}</v-list-item-title>
                    </v-list-item>
                    <!-- <v-btn outlined color="success">Contact Us</v-btn> -->
                </v-list>
            </v-col>
            <v-col cols="2" sm="2" class="d-flex justify-end d-flex d-sm-none">
                <v-app-bar-nav-icon class="mt-2" @click.stop="onDrawer()"></v-app-bar-nav-icon>
            </v-col>
        </v-row>
        
        
    </v-container>

    

    </v-app-bar>
    <frond-drawer-menu :drawer="drawer"/>
    </div>
</template>

<script>
    import FrondDrawerMenu from '../FrontDrawerMenu'
    export default {
        name: "Header",
        components: {
            FrondDrawerMenu
        },
        data(){
            return{
                menus:[
                    {title:'Home',      route:'/'},
                    {title:'Our Products', route:'/products'},
                    {title:'Sold Out',  route:'/sold'},
                    {title:'Contacts', route:'/contact'},
                ],
                drawer: false,
                search: "",
                pagination: {
                  page: 0,
                  size: 12,
                  sort: "views",
                  direction: "desc",
                  id: 0,
                  stock: 1,
                  search: ""
                }
            }
        },
        methods: {
            onDrawer () {
                this.drawer = !this.drawer
            },
            async onSearch () {
              this.pagination.search = ""
              this.pagination.id = 0
              // console.log('Search:', this.search)
              let word = this.search.toUpperCase()
              word = word.toString()
              word = word.substring(0, 3)
              if(word == 'EHB') {
                let total = this.search.length
                let id = this.search.substr(3, total)
                this.pagination.id = parseInt(id)
              }else{
                this.pagination.search = this.search
              }

              const formData = {
                page: this.pagination.page,
                size: this.pagination.size,
                sort: this.pagination.sort,
                direction: this.pagination.direction,
                active: 1,
                stock: 1,
                id: this.pagination.id,
                search: this.pagination.search
              }

              await this.$store.dispatch('getFrontSearch', formData)
            }
        }
    }
</script>

<style>
    .h1 {
        font-size: 32px;
        color: #4caf50;
        font-weight: 700;
        text-align: left;
        font-family: cursive;
    }
    a.v-list-item--active.v-list-item.v-list-item--link.theme--light:before {
        border-radius: 5px;
    }
    .navDrawerLeft .v-list-item.v-list-item--link.theme--light {
        text-align: center;
    }
</style>