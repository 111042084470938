<template>
    <div>
        <products-all />
    </div>
</template>
<script>
import { 
    ProductsAll
} from '../../Molecules'
export default {
    components: {
        ProductsAll
    },
    data () {
        return {

        }
    }
}
</script>